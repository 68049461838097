import Vue from 'vue'
import Main from './Main.vue'
import store from './store'
import VueAnime from 'vue-animejs';
import 'animate.css/animate.min.css';

Vue.config.productionTip = false
export const bus = new Vue();

Vue.use(VueAnime)

new Vue({
  render: h => h(Main),
  store,
}).$mount('#app')
