<template>
  <img v-if="gifUrl" :src="gifUrl" alt="GIF Sport" />
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      gifUrl: null,
      loading: false,
    };
  },
  methods: {
    async fetchRandomGif() {
      this.loading = true;
      const apiKey = "fbXP3Drk18YGZO7yXM8FQUaKaTuSpEDy";
      const tag = "workout";
      const url = `https://api.giphy.com/v1/gifs/random?api_key=${apiKey}&tag=${tag}&rating=g`;

      try {
        const response = await axios.get(url);
        this.gifUrl = response.data.data.images.original.url; // URL du GIF
      } catch (error) {
        console.error("Erreur lors de la récupération du GIF :", error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    // Charger un GIF au démarrage du composant
    this.fetchRandomGif();
  },
};
</script>

<style scoped>
.gif-container {
  text-align: center;
  margin: 20px;
}
img {
  max-width: 100%;
  height: 150px;
  margin: 10px 0;
}
button {
  padding: 10px 20px;
  background-color: #42b983;
  color: white;
  border: none;
  cursor: pointer;
}
</style>
