<template>
  <div class="block-container">
    <div class="sub-title" @click="hide">Calculs mentaux</div>
    <div v-if="isShowing"><button @click="generate">Générer</button></div>
    <div v-if="isShowing && generated" style="font-size: 3rem">
      {{ number1 }} x {{ number2 }}
    </div>
    <div v-if="!isChronoPlaying && isShowing && generated">Résultat</div>
    <div v-if="!isChronoPlaying && isShowing && generated" class="result" @click="showResult">
      {{ showingResult ? result : "= ?" }}
      <br />
      <span style="font-size: 1.5rem">{{
        showingResult ? resultSum : ""
      }}</span>
    </div>
    <div v-if="isChronoPlaying">
      <Gif/>
    </div>
  </div>
</template>

<script>
import Gif from "./Gif.vue";

export default {
  props: ["isChronoPlaying"],
  data() {
    return {
      isShowing: true,
      number1: 42,
      number2: 7,
      generated: false,
      showingResult: false,
    };
  },
  computed: {
    result() {
      return this.number1 * this.number2;
    },
    resultSum() {
      const sumDigits = (num) =>
        num
          .toString()
          .split("")
          .reduce((acc, digit) => acc + parseInt(digit), 0);

      var number = this.result;
      while (number >= 10) {
        number = sumDigits(number);
      }

      return number;
    },
  },
  methods: {
    hide() {
      this.isShowing = !this.isShowing;
    },
    generate() {
      const containsZero = (number) => number.toString().includes("0");
      do {
        this.number1 = this.getRandomNumber(200, 1000);
      } while (this.number1 % 10 === 0 || containsZero(this.number1));

      do {
        this.number2 = this.getRandomNumber(3, 10);
      } while (this.number2 % 10 === 0);

      this.generated = true;
      this.showingResult = false;
    },
    getRandomNumber(min, max) {
      // Min inclusive; Max exclusive
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    showResult() {
      this.showingResult = true;
    },
  },
  components: {
    Gif,
  },
};
</script>

<style>
.result {
  border: dotted 4px white;
  padding: 16px 32px;
  font-size: 3rem;
}
</style>