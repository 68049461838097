<template>
  <div class="content">
    <div class="title">SPORT</div>
    <Chronometer @play="onChronoPlay" @pause="onChronoPause" />
    <Maths :isChronoPlaying="isChronoPlaying"/>
    <!-- <Pace /> -->
  </div>
</template>

<script>
import Pace from "./Pace.vue";
import Maths from "./Maths.vue";
import Chronometer from "./Chronometer.vue";

export default {
  data() {
    return {
      isChronoPlaying: false,
    };
  },
  methods: {
    onChronoPlay() {
      this.isChronoPlaying = true;
    },
    onChronoPause() {
      this.isChronoPlaying = false;
    },
  },
  components: {
    Pace,
    Maths,
    Chronometer,
  },
};
</script>

<style>
html {
  background-color: black;
  background-image: url("static/img/TiledBackground.png");
  background-repeat: repeat;
  background-size: 150px;
  font-family: "Sports";
}

@font-face {
  font-family: "Sports";
  src: url("./static/fonts/Sports.ttf") format("truetype");
}

.title {
  font-size: 5rem;
}

.sub-title {
  font-size: 1.5rem;
  color: #ffff8f;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: center;
  row-gap: 1rem;
  color: white;
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 1);
  width: 100%;
  margin-bottom: 300px;
}

.block-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: center;
  row-gap: 1rem;

  background: #04273d;
  padding: 30px 15px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  width: 80%;
}

input {
  height: 32px;
  width: 78px;
  font-size: 26px;
  text-align: center;
  font-family: "Sports";
}

/* CSS */
button {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #5adaff 0,
    #5468ff 100%
  );
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono", monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

button:focus {
  box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
}

button:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  transform: translateY(-2px);
}

button:active {
  box-shadow: #3c4fe0 0 3px 7px inset;
  transform: translateY(2px);
}
</style>
