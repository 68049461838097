<template>
  <div class="block-container">
    <div class="sub-title" @click="hide">Chronomètre</div>
    <div v-show="isShowing" class="timer">
      <span>{{ minutesFormatted }}</span
      >: <span>{{ secondsFormatted }}</span
      >:
      <span>{{ centisecondsFormatted }}</span>
    </div>

    <div v-show="isShowing" class="buttons">
      <button @click="clearTime" v-show="isPaused">Clear</button>
      <button @click="togglePauseResume">
        {{ isPaused ? "Start" : "Pause" }}
      </button>
      <button @click="recordLap" v-show="isPaused">Tour</button>
    </div>

    <!-- Display laps -->
    <div v-show="isShowing" v-if="laps.length">
      <div v-for="(lap, index) in laps" :key="index">
        {{ formatTime(lap) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShowing: true,
      isPaused: true,
      time: 0, // Time in centiseconds
      laps: [],
      timerInterval: null,
    };
  },
  computed: {
    minutesFormatted() {
      return String(Math.floor(this.time / 6000)).padStart(2, "0");
    },
    secondsFormatted() {
      return String(Math.floor((this.time % 6000) / 100)).padStart(2, "0");
    },
    centisecondsFormatted() {
      return String(this.time % 100).padStart(2, "0");
    },
  },
  methods: {
    hide() {
      this.isShowing = !this.isShowing;
    },
    togglePauseResume() {
      if (this.isPaused) {
        this.startTimer();
      } else {
        this.pauseTimer();
      }
    },
    startTimer() {
      this.isPaused = false;
      this.$emit("play")
      this.timerInterval = setInterval(() => {
        this.time++;
      }, 10);
    },
    pauseTimer() {
      this.isPaused = true;
      this.$emit("pause")
      clearInterval(this.timerInterval);
    },
    clearTime() {
      if (this.time === 0) this.laps = [];
      this.time = 0;
    },
    recordLap() {
      this.laps.push(this.time);
    },
    formatTime(time) {
      const minutes = String(Math.floor(time / 6000)).padStart(2, "0");
      const seconds = String(Math.floor((time % 6000) / 100)).padStart(2, "0");
      const centiseconds = String(time % 100).padStart(2, "0");
      return `${minutes}:${seconds}:${centiseconds}`;
    },
  },
};
</script>

<style scoped>
.timer {
  font-size: 48px;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}
</style>