<template>
  <div class="block-container">
    <div class="sub-title" @click="hide">Calculateur d'allure</div>
    <div v-if="isShowing">
      <!-- Display input for speed in km/h -->
      <label for="speed">Vitesse (km / h):</label><br />
      <input type="number" id="speed" v-model="speed" />
    </div>

    <!-- Display the calculated pace -->
    <div v-if="isShowing">
      Allure (min / km):<br />
      <span style="font-size: 2rem">{{
        speed ? pace.minutes + "'" + pace.seconds : "0"
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShowing: false,
      speed: 6, // Speed in km/h
    };
  },
  computed: {
    pace() {
      if (this.speed > 0) {
        // Convert speed (km/h) to pace (time/km)
        // Formula: pace in hours per km = 1 / speed in km per hour
        // Convert pace to minutes and seconds
        let paceInHours = 1 / this.speed;
        let paceInMinutes = paceInHours * 60;
        let minutes = Math.floor(paceInMinutes);
        let seconds = Math.round((paceInMinutes - minutes) * 60);

        // Return the calculated pace
        return {
          minutes: minutes,
          seconds: seconds,
        };
      } else {
        return 0;
      }
    },
  },
  methods: {
    hide() {
      this.isShowing = !this.isShowing;
    },
  },
};
</script>
